import React from "react";
import "./Faqs.css";
import Imgtitle from "../../assets/img-title.png";

const Faqs = () => {
  return (
    <div className="faqs-wrapper">
      <img src={Imgtitle} alt="/" />
      <h1>FAQs</h1>
      <p className="faqs-p">
        Here you will find some of the most frequently asked questions by our community:
      </p>
      <div className="container-faq">
        <section className="column">
          <div>
            <label className="accordion">
              <input type="checkbox" name="checkbox-accordion" />
              <div className="accordion__header">WHAT IS AN NFT?</div>
              <div className="accordion__content">
                {/* <h6>Lorem ipsum dolor sit amet,</h6> */}
                <p>
                  NFT stands for "Non-Fungible Token", and they are a technology
                  enabling decentralized proof of ownership of online assets. To
                  put it simply, each NFT is coded completely uniquely. This
                  means that even though somebody may be able to copy the image
                  of your NFT, they will never own the unique token that backs
                  your image.
                </p>
              </div>
            </label>
            <label className="accordion">
              <input type="checkbox" name="checkbox-accordion" />
              <div className="accordion__header">
                HOW MANY PEACOCK WARRIORS ARE THERE?
              </div>
              <div className="accordion__content">
                <p>
                  The Total Supply for the first collection will be 999 Peacock
                  Warriors NFT.
                </p>
              </div>
            </label>
            <label className="accordion">
              <input type="checkbox" name="checkbox-accordion" />
              <div className="accordion__header">
                WHAT BLOCKCHAIN IS PEACOCK WARRIORS MINTING ON?
              </div>
              <div className="accordion__content">
                <p>
                  We are using the ERC-721 smart contract on the Ethereum
                  blockchain.
                </p>
              </div>
            </label>
            <label className="accordion">
              <input type="checkbox" name="checkbox-accordion" />
              <div className="accordion__header">
                WHY SHOULD I MINT A PEACOCK WARRIORS NFT?
              </div>
              <div className="accordion__content">
                <p>
                  Our objective is to create a community that are involved in
                  our upcoming game. We will make sure that you are going to
                  enjoy this journey together with our community.
                </p>
              </div>
            </label>
          </div>
        </section>
        <section className="column column-padding">
          <div>
            <label className="accordion">
              <input type="checkbox" name="checkbox-accordion" />
              <div className="accordion__header">
                WHEN IS THE LAUNCH DATE OF THE PLAY-TO-EARN GAME?
              </div>
              <div className="accordion__content">
                <p>Our Play-To-Earn Game will be launched in 01/June/2023.</p>
              </div>
            </label>
            <label className="accordion">
              <input type="checkbox" name="checkbox-accordion" />
              <div className="accordion__header">
                WILL THERE BE A SECOND COLLECTION?
              </div>
              <div className="accordion__content">
                <p>Yes, we plan to launch the second collection.</p>
              </div>
            </label>
            <label className="accordion">
              <input type="checkbox" name="checkbox-accordion" />
              <div className="accordion__header">HOW DO I BUY AN NFT?</div>
              <div className="accordion__content">
                <p>
                  To buy NFTs on the market, you will need a Metamask wallet. At
                  mint (buying an NFT when it initially launches), you will need
                  to connect your wallet to our mint website and approve the
                  transaction. Once completed you will receive your NFT into
                  your wallet and will be shown on your Opensea Profile!
                </p>
              </div>
            </label>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Faqs;
