import React from "react";
import Header from "../Header/Header";
import About from "../About/About";
import Roadmap from "../Roadmap/Roadmap";
import Trailer from "../Trailer/Trailer";
import Game from "../Game/Game";
import Faqs from "../Faq/Faqs";
import Footer from "../Footer/Footer";

const Home = () => {
  return (
    <>
      <Header />
      <About />
      <Roadmap />
      <Trailer />
      <Game />
      <Faqs />
      <Footer />
    </>
  );
};

export default Home;
