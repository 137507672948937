import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
// import * as s from "./styles/globalStyles";
import styled, { keyframes } from "styled-components";
import { hover } from "@testing-library/user-event/dist/hover";
import {IoChevronBackCircleSharp} from "react-icons/io5"
import { Link } from "react-router-dom";
import './Minting.css';
import mintgif from '../src/assets/loading.gif'
import {IoChevronBackOutline} from "react-icons/io5"



const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

  export const SpacerSmall = styled.div`
  height: 16px;
  width: 16px;
`;

// Used for providing space between components
export const SpacerMedium = styled.div`
  height: 24px;
  width: 24px;
`;

// Used for providing space between components
export const SpacerLarge = styled.div`
  height: 32px;
  width: 32px;
`;

export const StyledButton = styled.button`
  margin: 10px;
  width: 170px;
  height: 60px;
  padding: 15px 30px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 10px;
  display: block;
  border: 0px;
  // font-weight: 700;
  font-size: 23px;
  box-shadow: 0px 0px 14px -7px #f01919;
  background-image: linear-gradient(45deg, #ff2f2f 0%, #4b0505  51%, #ff2f2f  100%);
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;a
  :active {
    transform: scale(0.95);
  }
  :hover {
    background-position: right center;
    color: #fff;
    text-decoration: none;
  }
`;


export const StyledRoundButton = styled.button`
  border-radius: 100%;
  border: none;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  display: block;
  border: 0px;
  text-align:center;
  font-size: 18px;
  box-shadow: 0px 0px 14px -7px #f01919;
  background-image: linear-gradient(45deg, #ff2f2f 0%, #4b0505  51%, #ff2f2f  100%);
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;a
  :active {
    transform: scale(0.95);
  }
  :hover {
    background-position: right center;
    color: #fff;
    text-decoration: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 80%;
  border-radius: 20px;
  text-shadow: 0px 0px 10px red;
  color: whitesmoke;
  background-color: 	rgb(0,0,0, 0.2);
  box-shadow: 0px 5px 11px 2px rgb(240,128,128);

  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px dashed var(--secondary);
  background-color: var(--accent);
  border-radius: 100%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

export const StyledLink2 = styled.a`
color: white;
text-decoration: none;
`;

function Minting() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click Mint to mint your NFT.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 5) {
      newMintAmount = 5;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (

    <div className="mint-wrapper">       

      <div className="container">

        <SpacerSmall />
        <SpacerSmall />

        <div className="responsive-contaner" style={{flex: 1, width: "70%"}}>

          <div className="container"
            flex={2}
            jc={"center"}
            ai={"center"}
            style={{
              backgroundColor: "rgb(122 117 117 / 55%)",
              borderRadius: 24,
              border: "4px dashed var(--secondary)",
              boxShadow: "0px 0px 11px 0px rgba(255, 255, 255, 0.2)",
              margin: "auto",
              width:"100%",
              backdropFilter: "blur(15px)"
            }}
          >
                    <SpacerSmall />
                    <SpacerSmall />
                    <SpacerSmall />


        <Link to='/' style={{margin: "auto auto auto 20px", display: "flex", alignItems: "center"}} className="goback"><IoChevronBackOutline /><span className="goback-span"></span></Link>

        <p className="first-p"         
                style={{
                textAlign: "center",
                fontSize: 50,
                fontWeight: "bold",
                color: "#E73C35",
                margin: 0,
              }}
              
              > Mint Now
        </p>
        {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <p className="first-p"         
              style={{
                textAlign: "center",
                fontSize: 25,
                fontWeight: "bold",
              }}
            >Connect to Mint</p>
        ) :  <p className="first-p"         
        style={{
          textAlign: "center",
          fontSize: 50,
          fontWeight: "bold",
        }}
      >
        {data.totalSupply} / {CONFIG.MAX_SUPPLY}
      </p>
}
                                <SpacerSmall />
            <p className="third-p"
              style={{
                textAlign: "center",
                fontSize: 20,
              }}
            >
              <a target={"_blank"} href={CONFIG.SCAN_LINK} className="first-a">
                {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
              </a>
            </p>
            {blockchain.account === "" ||
                blockchain.smartContract === null ? ( " " ) :
          <img src={mintgif} alt='/' style={{width: "15%", height: "15%", marginTop: "3.5%"}}/>
          }
            <SpacerSmall />
            <SpacerSmall />
            {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
              <>
                <p className="first=p"
                  style={{ textAlign: "center", fontSize: 20 }}
                >
                  The sale has ended.
                </p>
                <p className="third-p"
                  style={{ textAlign: "center", fontSize: 20 }}
                >
                  You can still find {CONFIG.NFT_NAME} on
                </p>
                <SpacerSmall />
                <a target={"_blank"} href={CONFIG.MARKETPLACE_LINK} style={{color: "#e31e2b", fontSize: 23, fontStyle: "underline"}} className="first-a">
                  {CONFIG.MARKETPLACE}
                </a>
              </>
            ) : (
              <>
                {/* <p className="first-p"
                  style={{ textAlign: "center", fontSize: 25 }}
                >
                  1 {CONFIG.SYMBOL} costs {CONFIG.DISPLAY_COST}{" "}
                  {CONFIG.NETWORK.SYMBOL}.
                </p> */}
                <SpacerSmall />
                {/* <p className="third-p"
                  style={{ textAlign: "center", fontSize: 20 }}
                >
                  Excluding gas fees.
                </p> */}
                <SpacerSmall />
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <div className="container" ai={"center"} jc={"center"}>
                    <p className="third-p"
                      style={{
                        textAlign: "center",
                        fontSize: 20
                      }}
                    >
                      Connect to the {CONFIG.NETWORK.NAME} network
                    </p>
                    <SpacerSmall />
                    <button className="btn-btn"
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      CONNECT
                    </button>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <SpacerSmall />
                        <p className="third-p"
                          style={{
                            textAlign: "center",
                          }}
                        >
                          {blockchain.errorMsg}
                        </p>
                      </>
                    ) : null}
                  </div>
                ) : (
                  <>
                    <p className="third-p"
                      style={{
                        textAlign: "center",
                        fontSize: 20
                      }}
                    >
                      {feedback}
                    </p>
                    <SpacerMedium />
                    <div className="container" ai={"center"} jc={"center"} fd={"row"} id="flexrowbuttons">
                      <button className="round-btn"
                        style={{ lineHeight: 0.4 }}
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          decrementMintAmount();
                        }}
                      >
                        -
                      </button>
                      <SpacerMedium />
                      <p className="third-p"
                        style={{
                          textAlign: "center",
                        }}
                      >
                        {mintAmount}
                      </p>
                      <SpacerMedium />
                      <button className="round-btn"
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          incrementMintAmount();
                        }}
                      >
                        +
                      </button>
                    </div>
                    <SpacerSmall />
                    <div className="container" ai={"center"} jc={"center"} fd={"row"}>
                      <button className="btn-btn"
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          claimNFTs();
                          getData();
                        }}
                      >
                        {claimingNft ? "BUSY" : "MINT"}
                      </button>
                    </div>
                  </>
                )}
              </>
            )}
                                <SpacerSmall />
                                <SpacerSmall />

            <SpacerMedium />
          </div>
        </div>
        <SpacerMedium />
        <SpacerSmall />

        {/* <s.Container jc={"center"} ai={"center"} style={{ width: "70%" }}>
          <s.TextDescription
            style={{
              textAlign: "center",
              color: "var(--primary-text)",
            }}
          >
            Please make sure you are connected to the right network (
            {CONFIG.NETWORK.NAME} Mainnet) and the correct address. Please note:
            Once you make the purchase, you cannot undo this action.
          </s.TextDescription>
          <s.SpacerSmall />
          <s.TextDescription
            style={{
              textAlign: "center",
              color: "var(--primary-text)",
            }}
          >
            We have set the gas limit to {CONFIG.GAS_LIMIT} for the contract to
            successfully mint your NFT. We recommend that you don't lower the
            gas limit.
          </s.TextDescription>
        </s.Container> */}
      </div>
    </div>
  );
}

export default Minting;
