import React from "react";
import "./Header.css";
import Navbar from "../Navbar/Navbar";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <div className="header-wrapper">
      <Navbar />
      <div className="header-content">
        <h1 className="header-title">
          PEACOCK <br /> WARRIORS
        </h1>
        <p className="header-paragraph">
          A collection of 999 unique warriors NFTs which grant their holders
          access to the upcoming Play-2-Earn Game.
        </p>
        <div className="buttons-div">
          <div className="first-button">
          <Link to="/mint"><a href="#">
              <button className="mint-button">MINT NOW</button>
            </a>
            </Link>
          </div>
          <div className="second-button">
            <a href="https://opensea.io/collection/peacockwarriors" target="_blank">
              <button className="opensea-button">OPENSEA</button>
            </a>
          </div>
        </div>
        
      </div>
      <div className="scroll-downs">
            <div class="mousey">
              <div class="scroller"></div>
            </div>
          </div>
    </div>
  );
};

export default Header;
