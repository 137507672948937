import React, { useState } from "react";
import "./Roadmap.css";
import Imgtitle from "../../assets/img-title.png";
import Slider from "infinite-react-carousel";
import line from "../../assets/down-arrow.png";

const Roadmap = () => {
  const [currentTab, setCurrentTab] = useState("1");
  const tabs = [
    {
      id: 1,
      tabTitle: "●",
      title: "CONCEPT",
      image: "https://i.ibb.co/QMtq9gL/12.png",
      content:
        "After conducting extensive research on the current state of NFT projects, we have identified a gap in the market that can be filled by highlighting the beauty of peacocks. Our inspiration for choosing this idea comes from the natural beauty and fighting spirit of these magnificent creatures, which we believe is an untapped source of inspiration in the NFT community. Through our project, we aim to bring a new perspective to the world of digital art and offer collectors a unique and exquisite collection that celebrates the essence of the peacock.",
    },
    {
      id: 2,
      tabTitle: "●",
      title: "FIRST COLLECTION",
      image: "https://i.ibb.co/LQLXHKG/5.png",
      content: "We are thrilled to announce the launch of the first collection of Peacock Warriors NFTs, featuring 999 limited edition digital art pieces inspired by the beauty and fighting spirit of peacocks. With only 999 NFTs available in this collection, each one is a truly rare and valuable addition to any NFT collection. Don't miss your chance to own a piece of this exclusive collection and become a part of the upcoming Play-To-Earn Peacock Warriors Game",
    },
    {
      id: 3,
      tabTitle: "●",
      title: "GAME",
      image: "https://i.ibb.co/n1rdDSy/6.png",
      content: "Get ready to enter the world of Peacock Warriors with our brand new PLAY-TO-EARN game! In this thrilling fighting game, you can pit your Peacock Warrior NFT against others in epic battles to become the ultimate champion. With a leaderboard to track your progress and exciting prizes up for grabs, the competition is sure to be fierce. So gather your NFTs, sharpen your skills, and get ready to fight your way to the top of the Peacock Warriors leaderboard!",
    },
    {
      id: 4,
      tabTitle: "●",
      title: "SECOND COLLECTION",
      image: "https://i.ibb.co/FsvzbkZ/4.png",
      content: "Featuring 9999 unique and intricate designs, each warrior is a true masterpiece. Collectors and enthusiasts alike will be awed by the incredible attention to detail and artistry put into every piece. Don't miss out on the opportunity to own a piece of history and add one of these stunning Peacock Warriors to your collection today. The P2E game of Peacock Warriors will be updated to accept NFTs from the second collection as well and by this creating a greater audience to compete in the Peacocks world.",
    },
    {
      id: 5,
      tabTitle: "●",
      title: "TOKEN",
      image: "https://i.ibb.co/0F6R8dW/3.png",
      content: "We are thrilled to announce the launch of our very own token, which will be integrated into our Play-to-earn game. The Token distribution will be shown on the Website after the launch of the Token. Also we will update our game after the Token release.",
    },
  ];

  const handleTabClick = (e) => {
    setCurrentTab(e.target.id);
  };

  return (
    <div className="roadmap-wrapper" id="roadmap">
      <div className="roadmap-content">
        <img src={Imgtitle} alt="/" />
        <h1>ROADMAP</h1>
        <p>
          Through this roadmap, the Peacock Warriors NFT project aims to
          establish itself as a leading brand in the NFT community, known for
          its unique and beautiful collections and commitment to wildlife
          conservation.
        </p>
      </div>
      <div className="roadmap-slider">
        <div className="container-roadmap">
          <div className="roadmap-dates">
            <div className="dates-div">
              <h2>
                JULY 10 <span className="red-font">2022</span>
              </h2>
              <img src={line} alt="/" />
            </div>
            <div className="dates-div">
              <h2>
                OCTOBER 31 <span className="red-font">2022</span>
              </h2>
              <img src={line} alt="/" />
            </div>
            <div className="dates-div">
              <h2>
                JUNE 01 <span className="red-font">2023</span>
              </h2>
              <img src={line} alt="/" />
            </div>
            <div className="dates-div">
              <h2>
                OCTOBER 01 <span className="red-font">2024</span>
              </h2>
              <img src={line} alt="/" />
            </div>
            <div className="dates-div">
              <h2>
                MARCH 01 <span className="red-font">2025</span>
              </h2>
              <img src={line} alt="/" />
            </div>
          </div>
          <div className="tabs">
            {tabs.map((tab, i) => (
              <button
                key={i}
                id={tab.id}
                disabled={currentTab === `${tab.id}`}
                onClick={handleTabClick}
              >
                {tab.tabTitle}
              </button>
            ))}
          </div>
          <div className="content-roadmap">
            {tabs.map((tab, i) => (
              <div key={i}>
                {currentTab === `${tab.id}` && (
                  <div className="sliders-fields">
                    <div className="sliders-inner">
                      <img src={tab.image} />
                      <div className="second-inner">
                        <p className="title">{tab.title}</p>
                        <p id="roadmap-p">{tab.content}</p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Roadmap;
