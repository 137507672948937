import React from "react";
import "./GamePage.css";

const GamePage = () => {
  return (
    <div className="gamepage-wrapper">
      <div className="gamepage-content">
        <h1>STAY TUNED</h1>
        <h1>01/06/2023</h1>
        {/* <h1> UPCOMING PEACOCK WARRIORS PLAY-TO-EARN GAME</h1> */}
        <a href="/" alt="/">
          Back
        </a>
      </div>
    </div>
  );
};

export default GamePage;
