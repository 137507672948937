import React from "react";
import "./Game.css";
import Imgtitle from "../../assets/img-title.png";
import Game1 from "../../assets/game1.png";
import Game2 from "../../assets/game2.png";
import Game3 from "../../assets/game3.png";
import Game4 from "../../assets/game4.png";

const Game = () => {
  return (
    <div className="game-wrapper" id="play-to-earn">
      <img src={Imgtitle} alt="/" />
      <h1>GAME</h1>
      <p>
        Peacock Warriors NFT Game is an exciting new game that allows players to
        battle their Peacock NFTs against other players' Peacock NFTs for a
        chance to win prizes. With each win, players can increase their rank and
        reputation within the game, becoming a true Peacock Warrior champion.
        The Peacock Warriors NFT Game is a fun and engaging way to interact with
        the Peacock Warriors NFT collection and a great opportunity for players
        to showcase their strategic skills and win exciting prizes.
      </p>
      <div className="game-photos">
        <div className="game-content">
         <div className="photo"><img src={Game2} alt="/" /></div>
         <div className="photo"><img src={Game4} alt="/" /></div>
        </div>
        <div className="game-content">
         <div className="photo"><img src={Game1} alt="/" /></div>
         <div className="photo"><img src={Game3} alt="/" /></div>
        </div>
      </div>
      <div className="game-button">
        <a href="https://game.peacockwarriors.com/" target="_blank">
          <button className="playgame-button">PLAY GAME</button>
        </a>
      </div>
    </div>
  );
};

export default Game;
