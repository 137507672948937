import React from "react";
import firstimg from "../../assets/1.png";
import secondimg from "../../assets/2.png";
import thirdimg from "../../assets/3.png";
import fourthimg from "../../assets/4.png";
import fifthimg from "../../assets/5.png";
import sixthimg from "../../assets/13.png";
import sevenththimg from "../../assets/16.png";
import eightimg from "../../assets/17.png";
import nineimg from "../../assets/18.png";
import tenimg from "../../assets/21.png";
import "./Footer-new.css";
import Imgfooter from "../../assets/footer-img1.png";

const Footer = () => {
  return (
    <div className="footer-wrapper">
      <div className="marquee-wrapper">
        <div className="marquee">
          <div className="marquee__group">
            <img src={firstimg} alt="" />
            <img src={secondimg} alt="" />
            <img src={thirdimg} alt="" />
            <img src={fourthimg} alt="" />
            <img src={fifthimg} alt="" />
            <img src={sixthimg} alt="" />
            <img src={sevenththimg} alt="" />
          </div>

          <div aria-hidden="true" className="marquee__group">
            <img src={eightimg} alt="" />
            <img src={nineimg} alt="" />
            <img src={tenimg} alt="" />
            <img src={firstimg} alt="" />
            <img src={secondimg} alt="" />
            <img src={thirdimg} alt="" />
            <img src={fourthimg} alt="" />
          </div>
        </div>
        <div className="marquee marquee--reverse">
          <div className="marquee__group">
          <img src={firstimg} alt="" />
            <img src={secondimg} alt="" />
            <img src={thirdimg} alt="" />
            <img src={fourthimg} alt="" />
            <img src={fifthimg} alt="" />
            <img src={sixthimg} alt="" />
            <img src={sevenththimg} alt="" />
          </div>

          <div aria-hidden="true" className="marquee__group">
          <img src={eightimg} alt="" />
            <img src={nineimg} alt="" />
            <img src={tenimg} alt="" />
            <img src={firstimg} alt="" />
            <img src={secondimg} alt="" />
            <img src={thirdimg} alt="" />
            <img src={fourthimg} alt="" />
          </div>
        </div>
      </div>
      <div className="footer-content">
        <img src={Imgfooter} alt="/" />
        {/* <p>COPYRIGHT</p> */}
      </div>
    </div>
  );
};

export default Footer;
