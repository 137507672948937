import React from "react";
import "./About.css";
import Imgtitle from "../../assets/img-title.png";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
// import "swiper/css";
// import "swiper/css/effect-flip";
// import "swiper/css/pagination";
// import "swiper/css/navigation";
import { EffectFlip, Pagination, Navigation, Autoplay } from "swiper";
import aboutimg1 from "../../assets/14.png";
import aboutimg2 from "../../assets/19.png";
import aboutimg3 from "../../assets/20.png";
import aboutimg4 from "../../assets/9.png";
import aboutimg5 from "../../assets/10.png";
import aboutimg6 from "../../assets/15.png";
import "swiper/swiper.scss";
import "swiper/swiper-bundle.css";

const About = () => {
  return (
    <div className="about-wrapper" id="about">
      <div className="about-title">
        <img src={Imgtitle} alt="/" />
        <h1>ABOUT US</h1>
      </div>
      <div className="about-content">
        <div className="about-p">
          <p>
            The collection features a limited number of NFTs that are designed
            to embody the spirit of the peacock, known for their striking
            appearance and fierce determination. Each NFT will be a
            one-of-a-kind masterpiece that captures the essence of the Peacock
            Warrior. First collection of Peacock Warriors NFT is a collection of
            999 NFTs. Members of our collections will have the chance to feel the
            emotion of fighting in the upcoming Play-To-Earn Peacock Warriors
            Game!
          </p>
        </div>
        <div className="about-slider">
          <Swiper
            effect={"flip"}
            grabCursor={true}
            pagination={true}
            navigation={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: true,
            }}
            loop={true}
            modules={[EffectFlip, Pagination, Navigation, Autoplay]}
            className="mySwiper"
          >
            <SwiperSlide>
              <img src={aboutimg1} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={aboutimg2} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={aboutimg3} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={aboutimg4} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={aboutimg5} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={aboutimg6} />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default About;
