import "./Navbar.css";
import SkyLineLogo from "../../assets/logo.png";
import { useEffect } from "react";
import {TfiTwitter} from "react-icons/tfi";
import {RxDiscordLogo} from "react-icons/rx";
import Whitepaper from "../../assets/Whitepaper.pdf";

const Navbar = () => {

  useEffect(() => {
    const nav = document.querySelector('nav');
    const svg = document.querySelector('svg');
    const svg1 = document.getElementById('discord-black');
    window.addEventListener("scroll", () => {
      if (window.scrollY === 0) {
        nav.style.background = "transparent";
        nav.style.padding = "0rem";
        nav.style.top = "38px";
        svg.style.color = '#000';
        svg1.style.color = '#000';
      } else {
        nav.style.background = "white";
        nav.style.padding = "1rem";
        nav.style.top = "0px";
        svg.style.color = 'black';
        svg1.style.color = 'black';  
      }
    });
  }, []);

  const addBorder = (e) => {
    const links = document.getElementsByTagName("a");
    Array.from(links).forEach(el => el.classList.remove("active"));
    e.target.classList.add("active");
  };
  const handleNav = () => {
    const navCheck = document.getElementById("nav-toggle");
    navCheck.checked = false;
  }
  return (
    <nav>
      <div class="logo-mobile">
        <img src={SkyLineLogo} alt="Peacock Warriors" />
      </div>
      <input id="nav-toggle" type="checkbox" />
      <ul class="links">
        <li>
          <a href="#" onClick={handleNav} id="homeLink">
            Home
          </a>
        </li>
        <li>
          <a href="#about" onClick={handleNav} id="aboutLink">
            About
          </a>
        </li>
        <li>
          <a href="#roadmap" onClick={handleNav} id="aboutLink">
            Roadmap
          </a>
        </li>
        <div class="logo">
          <img src={SkyLineLogo} alt="Peacock Warriors" />
        </div>
        <li>
          <a href="#play-to-earn" onClick={handleNav} id="serviceLink">Play To Earn</a>
        </li>
        <li>
        <a href={Whitepaper} target="_blank" onClick={handleNav} id="">Whitepaper</a>
        </li>
        {/* <li>
          <a href="#contact" onClick={addBorder} id="contactLink">Kontakt</a>
        </li> */}
        <li>
        <div className="icons-row" id="black-svg">
         <a href="https://twitter.com/PeacockWarriors" target="_blank" id="svg-nav"><TfiTwitter/></a>
          <a href="https://discord.gg/49qwJxhFzm" target="_blank" id="svg-nav"><RxDiscordLogo id="discord-black"/></a>
        </div>
        </li>
      </ul>
      <label for="nav-toggle" class="icon-burger">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
      </label>
    </nav>
  );
};

export default Navbar;