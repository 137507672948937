import React from 'react';
import { render } from 'react-dom';
import './index.css';
import App from './App';
import store from "./redux/store";
import { Provider } from "react-redux";
// import "./styles/reset.css";


const root = document.getElementById('root');
render(

  <Provider store={store}>
    <App />
  </Provider>,

  root

);
