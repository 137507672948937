import './App.css';
import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from './components/Home/Home';
import Mint from './Minting';
import GamePage from "./components/GamePage/GamePage";

function App() {
  const [data, setData] = useState([]);
  const [loading, setloading] = useState(undefined);
  const [completed, setcompleted] = useState(undefined);

  useEffect(() => {
    setTimeout(() => {
      fetch("https://jsonplaceholder.typicode.com/posts")
        .then((response) => response.json())
        .then((json) => {
          console.log(json);
          setData(json);
          setloading(true);

          setTimeout(() => {
            setcompleted(true);
          }, 1000);
        });
    }, 2000);
  }, []);

  return (
    <>
    <div className="App">
      {!completed ? (
        <>
          {!loading ? (
            <div className="spinner">
              <span className="gif"></span>
            </div>
          ) : (
            <div className="completed">
              <span className="gif"></span>
            </div>
          )}
        </>
      ) : (
        <>
              <>
            <BrowserRouter>
              <Routes>
              <Route path='/' element={<Home />} />
                <Route path="/Mint" element={<Mint />} />
                <Route path="/Game" element={<GamePage />} />
              </Routes>
            </BrowserRouter>
          </>
        </>
      )}
    </div>
  </>
  );
}

export default App;
