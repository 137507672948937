import React from "react";
import "./Trailer.css";
import Imgtitle from "../../assets/img-title.png";
import Trailervid from '../../assets/ReklamaNFT.mp4'
import thumbnail from '../../assets/thumbnail.png'

const Trailer = () => {
  return (
    <div className="trailer-wrapper">
      <img src={Imgtitle} alt="/" />
      <h1>Trailer</h1>
      <div className="video-div">
      <iframe className="trailer-vid" src="https://www.youtube.com/embed/o-JCxwdc3M8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>
    </div>
  );
};

export default Trailer;
